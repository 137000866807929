import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Calendar, DateRange } from 'react-date-range';
import dayjs, { Dayjs } from 'dayjs';
import { dollarsNoCentsWithSymbol } from 'helpers/currency';
import { mediaMMdMax, mediaMSmMax } from 'helpers/breakpoints';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  bookingBoatIdAtom,
  bookingDurationAtom,
  isBookingMultiDayAtom,
  tripFinishDayjsAtom,
  tripStartDayjsAtom,
  bookingTripTimeAtom,
  tripStartFormattedAtom,
  tripFinishFormattedAtom,
  selectedBookingPackageAtom,
} from 'components/forms/bookingWidget/jotaiStore';
import { useBoatCalendar } from 'components/forms/bookingWidget/hooks';
import { useTripCalculator } from 'hooks/queries';
import { createMultiDayDurationOption } from 'components/forms/bookingWidget/utils';
import { EMPTY } from 'utils/atomUtils';
import LoadingSpinner from 'components/loading/LoadingSpinner';
import Checkbox from 'components/ui/Checkbox/Checkbox';
import Button from 'components/ui/Button/Button';
import { DURATION_TO_TEXT } from '../forms/boat/constants';
import { Flex, FlexCol, FlexJustifySpaceBetween } from '../primitives/flex';
import { useBreakpoint } from '../BreakpointProvider';
import ArrowDown from '../../../../public/images/homepage/search/arrow-down.svg';
import useRudderStackTrack from '../../hooks/useRudderStackTrack';
import { getNotAllowedDates } from './utils';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

const NewBookingCalendar = ({
  onChange,
  mobile = false,
  applyCallback,
  monthsToShow = 2,
  resetTripTime = false,
  hidePrice = false,
  hideMultiDay = false,
  dontFetch = false,
  autoApply = true,
  showMonthAndYearPickers = false,
  onMultiDayChange,
  minDate = new Date(),
  maxDate = undefined,
  showCancel = true,
  shownDate = undefined,
  showApply = true,
  mobileFullLength = false,
  hideMobileHeader,
  date = new Date(),
  isBirthDate = false,
  isOwner = false,
}) => {
  const rudderTrack = useRudderStackTrack();
  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm || breakpoints.md;

  const [tripStart, setTripStart] = useAtom(tripStartDayjsAtom);
  const [tripFinish, setTripFinish] = useAtom(tripFinishDayjsAtom);
  const tripStartFormatted = useAtom(tripStartFormattedAtom);
  const tripFinishFormatted = useAtom(tripFinishFormattedAtom);
  const [isMultiDay, setIsBookingMultiDay] = useAtom(isBookingMultiDayAtom);
  const bookingBoatId = useAtomValue(bookingBoatIdAtom);
  const setBookingDuration = useSetAtom(bookingDurationAtom);
  const setTripTime = useSetAtom(bookingTripTimeAtom);
  const bookingPackage = useAtomValue(selectedBookingPackageAtom);

  const [boatCalendarStartDate, setBoatCalendarStartDate] = useState<Dayjs>(dayjs(shownDate));
  const [applyClicked, setApplyClicked] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(tripStart && !dontFetch ? tripStart.toDate() : date);

  const [selectionRange, setSelectionRange] = useState({
    startDate: tripStart && !dontFetch ? tripStart.toDate() : date,
    endDate: tripFinish && !dontFetch ? tripFinish.toDate() : date,
    key: 'selection',
  });

  const isBookingMultiDay = isMultiDay && !isBirthDate;

  const yearCount = dayjs(maxDate).diff(minDate, 'years');
  const calendarRef = useRef();

  const { tripCalculation } = useTripCalculator(!isBirthDate);
  const { boatCalendar, isLoading: isLoadingNotAllowedDates } = useBoatCalendar(
    {
      boatId: bookingBoatId,
      startOn: boatCalendarStartDate ? boatCalendarStartDate.startOf('month').format('YYYY-MM-DD') : '',
      endOn: boatCalendarStartDate
        ? boatCalendarStartDate.clone().add(1, 'month').endOf('month').format('YYYY-MM-DD')
        : '',
      packageType: bookingPackage?.package_type,
    },
    {
      enabled: !dontFetch,
    }
  );

  const notAllowedDates = getNotAllowedDates(isBookingMultiDay, dontFetch)(boatCalendar);

  useEffect(() => {
    if (isBirthDate) return;

    if (isBookingMultiDay) {
      setBoatCalendarStartDate(dayjs(selectedDate).startOf('month'));
    } else {
      setBoatCalendarStartDate(dayjs(selectionRange?.startDate).startOf('month'));
    }
  }, [isBookingMultiDay]);

  useEffect(() => {
    if (isBirthDate) return;

    setBoatCalendarStartDate(tripStart && !dontFetch ? tripStart.startOf('month') : dayjs(date).startOf('month'));
  }, [tripStart]);

  useEffect(() => {
    setTimeout(() => {
      document.querySelector('.rdrSelected')?.scrollIntoView({
        block: 'center',
      });
    }, 150);
  }, [mobile]);

  const toggleMultiDay = () => {
    if (isBirthDate) return;

    const newDate = selectedDate;

    // switching from multiday back to calendar
    if (isBookingMultiDay) {
      setSelectedDate(newDate);
      setTripFinish(dayjs(newDate));
      // switching from calendar to multiday
    } else {
      setSelectionRange({
        startDate: newDate,
        endDate: newDate,
        key: 'selection',
      });
    }

    if (onMultiDayChange) onMultiDayChange(newDate, !isBookingMultiDay);
    setTripTime(EMPTY);

    setIsBookingMultiDay(!isBookingMultiDay);

    rudderTrack('selection_single_multi_day', {
      value: isBookingMultiDay ? 'single-day' : 'multi-day',
    });
  };

  const selectItem = async (trip_start, trip_finish = undefined) => {
    // do not select item if it's birthdate calendar
    if (isBirthDate) return;

    setTripStart(dayjs(trip_start));

    if (resetTripTime) {
      setTripTime(EMPTY);
    }

    if (trip_finish) {
      setTripFinish(dayjs(trip_finish));
    } else {
      setTripFinish(dayjs(trip_start));
    }

    rudderTrack('selection_calendar', {
      trip_start,
      trip_finish,
    });
  };

  const onShownDateChange = (startDate) => {
    let newDate = startDate;
    if (mobile) newDate = new Date(startDate.setMonth(startDate.getMonth() + 1));
    if (isBirthDate) return;

    setBoatCalendarStartDate(dayjs(newDate));
  };

  return (
    <StyledCalendar
      short={monthsToShow === 1}
      showMonthAndYearPickers={showMonthAndYearPickers}
      className={mobile && 'mobile'}
    >
      {mobile && (
        <CloseWrapper onClick={() => applyCallback()}>
          <CloseIcon />
        </CloseWrapper>
      )}
      {mobile && !hideMobileHeader && <Heading>Date</Heading>}

      {!hideMultiDay && (
        <Multiday>
          <Checkbox
            name="isMultiDay"
            id="isMultiDay"
            defaultChecked={false}
            label={
              <ToggleWrapper short={smallScreen || monthsToShow === 1}>
                <ToggleOption selected={!isBookingMultiDay} short={smallScreen || monthsToShow === 1}>
                  Single day
                </ToggleOption>
                <ToggleOption selected={isBookingMultiDay} short={smallScreen || monthsToShow === 1}>
                  Multi-day
                </ToggleOption>
                <TogglePill selected={isBookingMultiDay} short={smallScreen || monthsToShow === 1} />
              </ToggleWrapper>
            }
            owner={false}
            bold={false}
            onChange={toggleMultiDay}
          />
        </Multiday>
      )}

      {datePickerOpen && (
        <YearDropdown>
          {Array(yearCount + 1)
            .fill(dayjs(maxDate).year())
            .map((e, i) => e - i)
            .map((year) => (
              <Year
                isOwner={isOwner}
                active={year === dayjs(boatCalendarStartDate || selectedDate).year()}
                onClick={() => {
                  setDatePickerOpen(false);
                  if (calendarRef?.current) {
                    (calendarRef.current as any).changeShownDate(year, 'setYear');
                  }
                }}
                key={year}
              >
                {year}
              </Year>
            ))}
        </YearDropdown>
      )}

      <RelDiv
        className={[mobileFullLength && 'mobile-full-length', isLoadingNotAllowedDates && 'loading-dates'].join(' ')}
      >
        {isLoadingNotAllowedDates && (
          <Overlay>
            <LoadingSpinner color="#72D4BA" />
          </Overlay>
        )}

        {showMonthAndYearPickers && (
          <CurrentYear datePickerOpen={datePickerOpen} onClick={() => setDatePickerOpen(!datePickerOpen)}>
            {dayjs(boatCalendarStartDate || selectedDate).format('MMMM YYYY')}
            <ArrowDown />
          </CurrentYear>
        )}

        {!isBookingMultiDay && (
          <Calendar
            ref={calendarRef}
            minDate={minDate}
            maxDate={maxDate}
            shownDate={shownDate}
            date={selectedDate}
            monthDisplayFormat="LLLL yyyy"
            weekdayDisplayFormat="EEEEE"
            showDateDisplay={false}
            months={monthsToShow}
            disabledDates={notAllowedDates}
            color={isOwner ? '#0E7873' : '#0A4195'}
            rangeColors={isOwner ? ['#0E7873'] : ['#0A4195']}
            preventSnapRefocus
            disableAutoFocus
            direction={mobile ? 'vertical' : 'horizontal'}
            scroll={{
              enabled: mobile,
              monthHeight: 320,
            }}
            showMonthAndYearPickers={showMonthAndYearPickers}
            showSelectionPreview={false}
            onChange={(e) => {
              setSelectedDate(e);
              selectItem(e);
              if (onChange && autoApply) onChange(e);
            }}
            onShownDateChange={onShownDateChange}
          />
        )}

        {isBookingMultiDay && (
          <DateRange
            shownDate={shownDate}
            minDate={minDate}
            maxDate={maxDate}
            ranges={[selectionRange]}
            monthDisplayFormat="LLLL yyyy"
            weekdayDisplayFormat="EEEEE"
            disabledDates={notAllowedDates}
            showDateDisplay={false}
            preventSnapRefocus
            disableAutoFocus
            months={monthsToShow}
            color="#0A4195"
            rangeColors={['#0A4195']}
            direction={mobile ? 'vertical' : 'horizontal'}
            scroll={{
              enabled: mobile,
              monthHeight: 320,
            }}
            showMonthAndYearPickers={showMonthAndYearPickers}
            showSelectionPreview={false}
            onChange={(e) => {
              if (dayjs(e.selection.endDate).isSame(dayjs(e.selection.startDate))) {
                if (dayjs(selectionRange.startDate).month() !== dayjs(e.selection.startDate).month()) {
                  if (isBirthDate) return;

                  setBoatCalendarStartDate(dayjs(e.selection.startDate).startOf('month'));
                }
              }

              setSelectedDate(e.selection.startDate);
              setSelectionRange(e.selection);
              selectItem(e.selection.startDate, e.selection.endDate);

              setBookingDuration(createMultiDayDurationOption(e.selection.endDate, e.selection.startDate));

              if (onChange && autoApply) onChange(e.selection);
            }}
            onShownDateChange={onShownDateChange}
          />
        )}
      </RelDiv>

      <Controls short={monthsToShow === 1}>
        {!hidePrice && (
          <Price>
            {tripCalculation.boat_price ? `${dollarsNoCentsWithSymbol(tripCalculation.boat_price)}+` : ''}
            <Days>
              {isBookingMultiDay ? (
                <>{dayjs(selectionRange.endDate).diff(selectionRange.startDate, 'day') + 1} days</>
              ) : (
                <span>{tripCalculation?.duration && DURATION_TO_TEXT[tripCalculation?.duration]}</span>
              )}
            </Days>
          </Price>
        )}
        <Flex width="100%">
          <FlexCol width="100%">
            {isBookingMultiDay && tripStartFormatted === tripFinishFormatted && applyClicked && (
              <ErrorText>Enter end date to apply</ErrorText>
            )}
            <ButtonWrapper short={monthsToShow === 1} ml="auto">
              {showCancel && <CancelButton justText text="Cancel" onClick={() => applyCallback()} />}
              {showApply && (
                <SButton
                  variant={isOwner ? 'yellow' : 'renter'}
                  text="Apply"
                  onClick={() => {
                    setApplyClicked(true);
                    if ((tripStartFormatted !== tripFinishFormatted && isBookingMultiDay) || !isBookingMultiDay) {
                      applyCallback(isBookingMultiDay ? selectionRange : selectedDate);
                      setApplyClicked(false);
                    }
                  }}
                />
              )}
            </ButtonWrapper>
          </FlexCol>
        </Flex>
      </Controls>
    </StyledCalendar>
  );
};

const Year = styled.div<{ active: boolean; isOwner: boolean }>`
  text-align: center;
  color: rgb(10, 65, 149);
  font-weight: 600;
  width: fit-content;
  padding: 5px 10px;
  align-items: center;
  //padding-bottom: 10px;
  //min-width: 25%;
  ${({ active }) =>
    active &&
    css`
      color: white !important;
      background: 'red';
      border-radius: 5px;
    `}

  ${({ active, isOwner }) =>
    active &&
    isOwner &&
    css`
      color: white !important;
      background: #0e7873;
      border-radius: 5px;
    `}
`;

const YearDropdown = styled.div`
  position: absolute;
  width: calc(100% + 40px);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 10px;
  margin-top: 40px;
  z-index: 100000;
  overflow-y: scroll;
  left: -20px;
  top: 10px;
  box-shadow: 0px 14px 11px -11px inset rgb(0 0 0 / 10%);
  height: calc(100% - 30px);
  background: white;
  padding: 10px 33px;
`;

const CurrentYear = styled.div<{ datePickerOpen: boolean }>`
  display: flex;
  gap: 7px;
  align-items: center;
  position: absolute;
  padding-left: 0.833em;
  z-index: 1000;
  padding-top: 10px;
  color: #17233c;
  font-weight: 600;
  svg {
    transition: all 150ms;
    ${({ datePickerOpen }) =>
      datePickerOpen &&
      css`
        transform: rotate(180deg);
      `}
  }
`;

const ButtonWrapper = styled(Flex)<{ short: boolean }>`
  ${mediaMMdMax} {
    margin-left: 0;
  }

  ${({ short }) =>
    short &&
    css`
      justify-content: space-between;
      margin-left: 0;
    `}
`;

const CancelButton = styled(Button)`
  span {
    font-weight: 500 !important;
  }
  ${mediaMMdMax} {
    display: none !important;
  }
`;

const SButton = styled(Button)`
  padding: 0 30px !important;
  min-height: 55px;
  ${mediaMMdMax} {
    width: 100%;
  }
`;

const ErrorText = styled.div`
  color: rgb(255, 0, 0);
  text-align: right;
  padding-bottom: 5px;
  font-size: 12px;

  ${mediaMMdMax} {
    font-size: 14px;
    position: absolute;
    bottom: 85px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 222222222;
  }
`;

const ToggleWrapper = styled(FlexJustifySpaceBetween)<{ short: boolean }>`
  width: 240px;
  align-items: center;
  border: 1px solid rgba(190, 190, 190, 0.7);
  border-radius: 60px;
  height: 50px;
  position: relative;

  ${({ short }) =>
    short &&
    css`
      height: 35px;
      width: 170px;
    `}
`;

const ToggleOption = styled.div<{ selected: boolean; short: boolean }>`
  z-index: 2;
  font-weight: 600;
  font-size: 14px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 107px;

  color: ${({ selected }) => (selected ? 'white' : '#17233c')};
  width: 50%;
  transition: color 220ms linear;

  text-align: center;
  cursor: pointer;
  &:nth-child(2) {
    padding-right: 3px;
  }
  &:nth-child(1) {
    padding-left: 5px;
  }

  ${({ short }) =>
    short &&
    css`
      height: 27px;
      font-size: 12px;
      width: 85px;
    `}
`;

const TogglePill = styled.div<{ selected: boolean; short: boolean }>`
  width: 120px;
  height: 44px;
  top: 2px;
  left: ${({ selected }) => (selected ? 'calc(50% - 3px)' : '2px')};
  position: absolute;
  background: #0a4195;
  border-radius: 107px;
  z-index: 1;
  transition: left 200ms ease-out;

  ${({ short, selected }) =>
    short &&
    css`
      top: 3px;
      background: #1e53bb;
      left: ${selected ? 'calc(50% - 3px)' : '3px'};
      height: 27px;
      width: 85px;
    `}
`;

const CloseIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4568 0.67899C10.6954 0.440337 11.0824 0.440337 11.321 0.67899C11.5597 0.917644 11.5597 1.30458 11.321 1.54323L1.54323 11.321C1.30458 11.5597 0.917644 11.5597 0.67899 11.321C0.440337 11.0824 0.440337 10.6954 0.67899 10.4568L10.4568 0.67899Z"
      fill="#5E696A"
      stroke="#5E696A"
      strokeWidth="0.4"
    />
    <path
      d="M11.321 10.4568C11.5597 10.6954 11.5597 11.0824 11.321 11.321C11.0824 11.5597 10.6954 11.5597 10.4568 11.321L0.67899 1.54323C0.440337 1.30458 0.440337 0.917644 0.67899 0.67899C0.917644 0.440337 1.30458 0.440337 1.54323 0.67899L11.321 10.4568Z"
      fill="#5E696A"
      stroke="#5E696A"
      strokeWidth="0.4"
    />
  </svg>
);

const CloseWrapper = styled.div`
  background: #f5f7f9;
  display: flex;
  cursor: pointer;
  width: fit-content;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 15px;
`;

const Heading = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 20px;
`;

const RelDiv = styled.div`
  position: relative;
  overflow: hidden;
  max-height: 70vh;

  &.loading-dates .rdrDayNumber span {
    color: #aeb9bf !important;
  }

  &.mobile-full-length {
    overflow: auto;
    max-height: unset;
  }
`;

const Multiday = styled.div`
  display: flex;
  justify-content: center;
  input {
    display: none;
  }
  padding-left: 10px;
  margin-bottom: 15px;
  min-height: 27px;
`;

const Controls = styled.div<{ short: boolean }>`
  display: flex;
  align-items: center;

  width: 100%;

  button {
    margin-left: auto;

    ${({ short }) =>
      short &&
      css`
        margin-left: 0;
      `}

    span {
      padding: 0 20px;
    }
  }

  ${mediaMSmMax} {
    bottom: 0px;
    background-color: white;
    box-shadow: 0px -5px 6px -1px rgb(0 0 0 / 10%);
    height: 84px;
    z-index: 101;
    position: fixed;
    left: 0px;
    padding: 16px;

    button {
      height: 100%;
    }
  }
`;

const Price = styled.div`
  font-weight: 700;
  font-size: 21px;
`;

const Days = styled.div`
  color: #5e696a !important;
  font-size: 13px;
  font-weight: 400;
`;

const Overlay = styled.div`
  position: absolute;
  cursor: not-allowed;
  z-index: 100;
  width: 100%;
  height: 95%;
  border-radius: 5px;

  span {
    top: 50%;
    left: 50%;
  }
`;

export const StyledCalendar = styled.div<{ short: boolean; showMonthAndYearPickers: boolean }>`
  position: relative;
  width: fit-content;
  width: 100%;

  .rdrMonth {
    ${({ short }) =>
      short &&
      css`
        width: 300px;
      `}

    ${mediaMSmMax} {
      width: 100%;
    }
    .rdrMonthName {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      color: #102a5e;
      ${mediaMSmMax} {
        text-align: left;
      }
      ${({ showMonthAndYearPickers }) =>
        showMonthAndYearPickers &&
        css`
          color: white;
        `}
    }
  }

  .rdrDay {
    height: 44px;
  }

  &.mobile {
    margin: auto;

    .rdrWeekDays {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    }

    .rdrMonthAndYearWrapper {
      display: none;
    }

    .rdrWeekDays {
      ${mediaMSmMax} {
        padding: 0;
      }
      border-bottom: 1px solid #dbdfe5;
    }
  }

  .rdrDayDisabled {
    background-color: unset;
    color: #dbdfe5;
  }

  .rdrSelected {
    border-radius: 6px;

    & ~ span {
      background: none !important;
    }
  }

  .rdrDayStartPreview {
    border: none;
  }

  .rdrDayNumber:after {
    border: none !important;
  }

  .rdrDayHovered {
    .rdrDayStartPreview {
      background: rgba(219, 223, 229, 0.5);
      border-radius: 6px !important;
    }
  }

  .rdrDayNumber {
    color: rgb(16, 42, 94);
    font-weight: 600;
    font-size: 14px;
  }

  .rdrCalendarWrapper {
    position: relative;
    ${mediaMSmMax} {
      width: calc(100vw - 40px);
    }
  }

  .rdrMonth {
    ${mediaMSmMax} {
      padding: 0;
    }
  }

  .rdrMonthAndYearWrapper {
    position: absolute;
    width: 100%;
    top: -18px;

    ${({ showMonthAndYearPickers }) =>
      showMonthAndYearPickers &&
      css`
        justify-content: end;
      `}

    .rdrMonthAndYearPickers {
      padding-top: 6px;
      display: none;
      select {
        text-align: left;
      }
    }

    .rdrPprevButton {
      transform: rotateZ(180deg);
    }

    .rdrNextPrevButton {
      background: url('/images/next-arrow.svg') no-repeat;
      background-position: center;
      background-color: #f5f7f9;
      background-size: 22%;
      width: 27px;
      height: 27px;
      border-radius: 30px;

      i {
        display: none;
      }
    }
  }

  .rdrWeekDay {
    color: #8e9697;
  }

  .rdrDayPassive {
    visibility: hidden;
  }

  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    top: 4px;
    bottom: 4px;
  }

  .rdrStartEdge,
  .rdrDayStartPreview {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
  }

  .rdrEndEdge,
  .rdrDayEndPreview {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }

  .rdrDayInPreview,
  .rdrDayEndPreview,
  .rdrDayStartPreview {
    border-color: transparent;
  }

  .rdrDayStartPreview {
  }

  .rdrDayEndPreview {
    background: rgba(219, 223, 229, 0.5);
  }

  .rdrStartEdge {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .rdrDayEndOfWeek > .rdrInRange,
  .rdrDayStartOfWeek > .rdrInRange {
    border-radius: 0px;
  }

  .rdrDayToday {
    border-radius: 8px;

    span {
      font-size: 14px;

      ${mediaMSmMax} {
        font-size: 16px;
      }
    }

    span:after {
      display: none;
    }
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: white;
  }
`;

export default NewBookingCalendar;
